import React, { useState,useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar"; // Import Sidebar component
import ConnectBrokerModal from "../components/ConnectBrokerModal";
import axios from 'axios';
import HomePage from "../components/homepage";
import { SidebarContext } from "../App";

const api_ip = process.env.REACT_APP_API_IP;
const api_port = process.env.REACT_APP_API_PORT;

export default function Navbar({ isLoggedIn, userName }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext); // Sidebar state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(''); 
  const [showSnackbar, setShowSnackbar] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  // Function to check if the user is connected
  const checkConnection = async () => {
    setLoading(true);
    
    if (!token) {
      alert("You need to be logged in to check the connection.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `https://${api_ip}:${api_port}/my_admin/get-access-token/`,
        {
          headers: {
            Authorization: `Token ${token}` // Pass token in Authorization header
          },
        }
      );

      if (response.data.connected) {
        triggerSnackbar("You are already connected to Fyers."); 
      } else {
        setIsModalOpen(true);
        triggerSnackbar("You are not connected to the Fyers. Please connect"); 
      }
    } catch (error) {
      console.error("Error checking connection:", error);
      triggerSnackbar("Failed to check connection. Please try again.");
    }

    setLoading(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };


  // Show snackbar with a message
  const triggerSnackbar = (message) => {
    setSnackbarMessage(message);
    setShowSnackbar(true);
    setTimeout(() => {
      setShowSnackbar(false);
    }, 3000); // Hide after 3 seconds
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    handleMenuClose();
  };

  const handleLogout = () => {
    localStorage.setItem("token", "");
    navigate("/");
  };

  // Modal open handler
  const handleOpenModal = () => {
    if (!isConnected) {
      checkConnection();
    }
  };

  // Modal close handler
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
        {localStorage.getItem("token")?
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleSidebar}
          >
            <MenuIcon />
          </IconButton>:<></>}

          {/* Logo or Brand Name */}
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, cursor: "pointer" }}
            onClick={() => handleNavigation("/")}
          >
            Silent Rage
          </Typography>

          {/* Desktop Buttons */}
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {!localStorage.getItem("token") ? (
              <>
                <Button color="inherit" onClick={() => handleNavigation("/sign-in")}>
                  Login
                </Button>
                <Button color="inherit" onClick={() => handleNavigation("/sign-up")}>
                  Sign Up
                </Button>
              </>
            ) : (
              <>
                {localStorage.getItem("username") === "Admin" && (
                  <Button color="inherit" onClick={() => handleNavigation("/admin-panel-page")}>
                    Admin
                  </Button>
                )}
                <Button
                  style={{ color: "white" }}
                  onClick={handleOpenModal}
                  disabled={loading} // Disable button while checking
                >
                  {loading ? "Checking..." : "Connect"}
                </Button>
                <ConnectBrokerModal open={isModalOpen} onClose={handleCloseModal} />
                <Button style={{ color: "white" }}>
                  Hello, {localStorage.getItem("username")}
                </Button>
                <Button style={{ color: "white" }} onClick={handleLogout}>
                  Logout
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Sidebar Component */}
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       {/* Snackbar */}
       {showSnackbar && <div className="snackbar show">{snackbarMessage}</div>}
    </>
  );
}
