import React, { useState } from 'react';
import {
  Box,
  Container,
  TextField,
  Typography,
  Button,
  Grid,
  Link,
  CircularProgress,
  Paper,
  Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';

const api_ip = process.env.REACT_APP_API_IP;
const api_port = process.env.REACT_APP_API_PORT;

const SignUpPage = () => {
  const navigate = useNavigate();

  // Form state variables
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Loading and error state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    // Check if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }

    try {
      // Send data to the API
      const response = await axios.post(`https://${api_ip}:${api_port}/authenticate/register/`, {
        first_name: firstName,
        last_name: lastName,
        username,
        email,
        password,
      });

      // Handle successful registration
      if (response.status === 201) {
        setError(response.data.message || 'User created successfully!');
        navigate('/sign-in'); // Redirect to sign-in page
      }
    } catch (err) {
      // Handle backend errors
      if (err.response?.status === 400) {
        // Show the error message from the backend
        setError(err.response.data.error || 'Invalid input. Please check your data.');
      } else {
        setError('An error occurred during registration. Please check your email.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#f4f6f8' }}>
      <Container maxWidth="xs">
        <Paper sx={{ padding: 3, boxShadow: 8, borderRadius: 3 }}>
          <Typography variant="h3" align="center" sx={{ color: '#1976d2', marginBottom: 2, fontWeight: 'bold' }}>
            Sign Up
          </Typography>

          {/* Error Message */}
          {error && (
            <Typography variant="body2" color="error" align="center" sx={{ marginBottom: 2 }}>
              {error}
            </Typography>
          )}

          <form onSubmit={handleSubmit}>
            {/* First Name Input */}
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              sx={{ marginBottom: 2 }}
            />

            {/* Last Name Input */}
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              sx={{ marginBottom: 2 }}
            />

            {/* Username Input */}
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{ marginBottom: 2 }}
            />

            {/* Email Input */}
            <TextField
              label="Email Address"
              variant="outlined"
              fullWidth
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ marginBottom: 2 }}
            />

            {/* Password Input */}
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              required
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ marginBottom: 2 }}
            />

            {/* Confirm Password Input */}
            <TextField
              label="Confirm Password"
              variant="outlined"
              fullWidth
              required
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              sx={{ marginBottom: 2 }}
            />

            {/* Submit Button */}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={loading}
              sx={{
                marginBottom: 2,
                padding: 1.5,
                fontSize: '1rem',
                textTransform: 'none',
                borderRadius: '50px',
                '&:hover': {
                  backgroundColor: '#1565c0',
                },
              }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign Up'}
            </Button>

            <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                Already have an account?{' '}
                <Link 
                  component={RouterLink} 
                  to="/sign-in" 
                  variant="body2" 
                  sx={{ color: '#1976d2', textDecoration: 'none' }}
                >
                  Sign In
                </Link>
              </Typography>
            </Grid>
          </Grid>

            <Divider sx={{ marginBottom: 2 }} />
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default SignUpPage;
