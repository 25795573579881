import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Typography, CircularProgress } from '@mui/material';

const api_ip = process.env.REACT_APP_API_IP;
const api_port = process.env.REACT_APP_API_PORT;

const ConnectBrokerModal = ({ open, onClose }) => {
  const [secretKey, setSecretKey] = useState('');
  const [clientId, setClientId] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // For loading state
  const [errorMessage, setErrorMessage] = useState('');  // For error handling
  const [successMessage, setSuccessMessage] = useState('');  // For success message

  const handleSubmit = async () => {
    if (secretKey && clientId) {
      setIsLoading(true); // Start loading state
      setErrorMessage(''); // Clear any previous errors
      setSuccessMessage(''); // Clear any previous success messages

      const token = localStorage.getItem('token');
      if (!token) {
        console.log({ message: 'No token found. Please login.', type: 'error' });
        return;
      }


      const apiUrl = `https://${api_ip}:${api_port}/connect/api-connection`; // Your API URL
      const bodyData = {
        client_id: clientId,
        secret_id: secretKey,
      };

      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
             Authorization: `Token ${token}`,
          },
          body: JSON.stringify(bodyData),
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Success:', data);

          // Check if the message contains a URL and redirect the user
          if (data.message && data.message.startsWith('https://')) {
            window.open(data.message, '_blank'); // Redirect to the URL
          } else {
            setSuccessMessage('Successfully connected to broker!');
            onClose(); // Close the modal
          }
        } else {
          const errorData = await response.json();
          setErrorMessage(errorData?.message || 'Failed to connect to the broker.');
        }
      } catch (error) {
        console.error('Error:', error);
        setErrorMessage('An error occurred while connecting to the broker.');
      } finally {
        setIsLoading(false); // Stop loading state
      }
    } else {
      alert('Please fill in both fields.');
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '2rem',
          borderRadius: '8px',
          width: '400px',
          boxShadow: 24,
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: '1rem' }}>Connect to Broker</Typography>
        
        {/* Secret Key Input */}
        <TextField
          label="Secret Key"
          fullWidth
          value={secretKey}
          onChange={(e) => setSecretKey(e.target.value)}
          sx={{ marginBottom: '1rem' }}
        />
        
        {/* Client ID Input */}
        <TextField
          label="Client ID"
          fullWidth
          value={clientId}
          onChange={(e) => setClientId(e.target.value)}
          sx={{ marginBottom: '1.5rem' }}
        />

        {/* Submit Button */}
        <Button
          onClick={handleSubmit}
          fullWidth
          variant="contained"
          color="primary"
          sx={{ padding: '1rem', fontSize: '1.2rem' }}
          disabled={isLoading}  // Disable button when loading
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
        </Button>

        {/* Success and Error Messages */}
        {errorMessage && (
          <Typography sx={{ color: 'red', marginTop: '1rem' }}>{errorMessage}</Typography>
        )}
        {successMessage && (
          <Typography sx={{ color: 'green', marginTop: '1rem' }}>{successMessage}</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default ConnectBrokerModal;
