import React from "react";
import "../css/Sidebar.css"; // Sidebar-specific styles
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    toggleSidebar();
    navigate(path);
  };

  const handleLogout = () => {
    localStorage.setItem("token", "");
    navigate("/");
  };

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="sidebar-header">
        <h2>Menu</h2>
        <button className="close-btn" onClick={toggleSidebar}>
          ✕
        </button>
      </div>
      <div className="sidebar-content">
        <Button
          fullWidth
          className="sidebar-btn"
          onClick={() => handleNavigation("/")}
        >
          Home
        </Button>
        <Button
          fullWidth
          className="sidebar-btn"
          onClick={() => handleNavigation("/settings")}
        >
          Connect
        </Button>
        <Button
          fullWidth
          className="sidebar-btn"
          onClick={() => handleNavigation("/profile")}
        >
          Profile
        </Button>
        <Button
          fullWidth
          className="sidebar-btn logout"
          onClick={handleLogout}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default Sidebar;
