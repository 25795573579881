import React, { useState, useEffect } from 'react';
import '../css/HomePage.css';
import { useNavigate } from 'react-router-dom'; 
import StartTradingModal from './StartTradingModal';
import axios from 'axios';

const api_ip = process.env.REACT_APP_API_IP;
const api_port = process.env.REACT_APP_API_PORT;

const HomePage = () => {
  const [isAlgoStarted, setIsAlgoStarted] = useState(false);
  const [timer, setTimer] = useState(0); // Timer in seconds
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [startTime, setStartTime] = useState(null); // This will store the start time from the API
  const [lotSize, setLotSize] = useState(1);
  const [stopLoss, setStopLoss] = useState(40);
  const [target, setTarget] = useState(40);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [taskStatus, setTaskStatus] = useState(null); // State to track the task status
  const navigate = useNavigate(); // Initialize useNavigate hook
  const isAuthenticated = localStorage.getItem('token'); // or use any other method to check auth status

  // Redirect to sign-in page if not authenticated
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/sign-in'); // Redirect to sign-in page if not authenticated
    }
  }, [isAuthenticated, navigate]); // Dependency array to run the effect when isAuthenticated changes

  // Fetch task status and start time from the API
  useEffect(() => {
    const fetchTaskStatus = async () => {
      const token = localStorage.getItem('token'); // Retrieve token for API calls
      try {
        const response = await axios.get(`https://${api_ip}:${api_port}/strategy/check-task/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (response.data.status === 'PENDING') {
          setTaskStatus('PENDING');
          setIsAlgoStarted(true);
          setStartTime(new Date(response.data.start_time)); // Set start time from API
        } else {
          setTaskStatus('COMPLETED');
        }
      } catch (error) {
        console.error('Error fetching task status:', error);
      }
    };

    fetchTaskStatus();
  }, []); // Run once when the component mounts

  // Timer update logic: Update every second and calculate based on `startTime`
  useEffect(() => {
    let interval;
    if (startTime) {
      interval = setInterval(() => {
        const elapsed = Math.floor((new Date() - startTime) / 1000); // Calculate elapsed time
        setTimer(elapsed); // Set timer based on elapsed time
      }, 1000); // Update every second
    }

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [startTime]); // Only run this effect when `startTime` changes

  // Timer formatting function to display time in HH:MM:SS format
  const formatTime = (seconds) => {
    const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    const secs = String(seconds % 60).padStart(2, '0');
    return `${hours}:${minutes}:${secs}`;
  };

  if (!isAuthenticated) {
    return null; // Avoid rendering the rest of the component while redirecting
  }

  // Show snackbar with a message
  const triggerSnackbar = (message) => {
    setSnackbarMessage(message);
    setShowSnackbar(true);
    setTimeout(() => {
      setShowSnackbar(false);
    }, 5000); // Hide after 5 seconds
  };

  const startAlgoTrading = () => {
    setIsAlgoStarted(true);
    triggerSnackbar('Algo Trading Started!');
    setIsModalOpen(false);
  };

  const handleOpenModal = async () => {
    const membershipExpiry = parseInt(localStorage.getItem('membership_expiry') || '0', 10);
    const token = localStorage.getItem('token'); // Retrieve token for API calls
  
    if (membershipExpiry === 0) {
      triggerSnackbar('Membership Expired! Please recharge your plan.');
    } else {
      try {
        // Call the get-access-token API
        const response = await axios.get(
          `https://${api_ip}:${api_port}/my_admin/get-access-token/`,
          {
            headers: {
              Authorization: `Token ${token}`, // Pass token in Authorization header
            },
          }
        );
  
        if (response.data.connected) {
          triggerSnackbar('You are connected to the broker.');
          setIsModalOpen(true); // Open modal to prompt user to connect
        } else {
          triggerSnackbar('You are not connected to the broker. Please connect by clicking the Connect button.'); // Show this message if not connected
        }
      } catch (error) {
        console.error('Error checking connection:', error);
        triggerSnackbar('Failed to check connection. Please try again.');
      }
    }
  };  

  // Function to handle modal close
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const stopAlgoTrading = async () => {
    const token = localStorage.getItem('token'); // Retrieve token for API calls
    try {
      // Call the stop-trading API with GET request
      const response = await axios.get(
        `https://${api_ip}:${api_port}/strategy/stop-trading`,
        {
          headers: {
            Authorization: `Token ${token}`, // Pass token in Authorization header
          },
        }
      );
      
      // Check if the API call was successful
      if (response.status === 202) {
        setIsAlgoStarted(false); // Stop the trading
        setTaskStatus('COMPLETED'); // Update task status to COMPLETED
        triggerSnackbar('Algo Trading Stopped!');
        setTimer(0); // Reset the timer
      }
    } catch (error) {
      console.error('Error stopping trading:', error);
      triggerSnackbar('Failed to stop trading. Please try again.');
    }
  };

  const handleFormSubmit = () => {
    console.log({ lotSize, stopLoss, target });
    setShowModal(false);
    startAlgoTrading(); // Start trading after submitting the form
  };

  const membership_expiry = localStorage.getItem("membership_expiry");
  const blinkerStyle = {
    animation: "blinker 2s linear infinite",
    color: "red", 
  };

  return (
    <div className="container">
      <div className="header">
        <h1>Algo Trading Dashboard</h1>
        <p>Streamline your trading with advanced algorithms.</p>
          {membership_expiry <= 5 && (
          <div>
            <p style={blinkerStyle}>
              {membership_expiry == 0 
                ? "⚠️ Purchase the Membership to continue !" 
                : `⚠️ Your membership will expire in ${membership_expiry} days`}
            </p>
            <style>
              {`
                @keyframes blinker {
                  50% {
                    opacity: 0;
                  }
                }
              `}
            </style>
          </div>
        )}
      </div>

      <div className="card">
        <h2>Algo Trading</h2>
        <p>Start and monitor your algorithmic trading strategy.</p>
        {taskStatus === 'PENDING' ? (
          <>
            {/* Stop Trading Button */}
            <button className="stop-btn" onClick={stopAlgoTrading}>
              Stop Trading
            </button>
            {/* Timer */}
            {startTime && <div className="timer">Time: {formatTime(timer)}</div>}
          </>
        ) : (
          <>
            {/* Start Trading Button */}
            <button className="start-btn" onClick={handleOpenModal}>
              Start Trading
            </button>
            {/* Modal */}
            <StartTradingModal
              open={isModalOpen}
              onClose={handleCloseModal}
              onStartTrading={startAlgoTrading}
              setTaskStatus={setTaskStatus}
              setIsAlgoStarted={setIsAlgoStarted} 
              setSnackbarMessage={setSnackbarMessage}
            />
          </>
        )}
      </div>

      {/* Snackbar */}
      {showSnackbar && <div className="snackbar show">{snackbarMessage}</div>}

      {showModal && (
        <div className="modal">
          <h2>Set Algo Parameters</h2>
          <input
            type="number"
            placeholder="Lot Size"
            value={lotSize}
            onChange={(e) => setLotSize(e.target.value)}
          />
          <input
            type="number"
            placeholder="Stop Loss (%)"
            value={stopLoss}
            onChange={(e) => setStopLoss(e.target.value)}
          />
          <input
            type="number"
            placeholder="Target (%)"
            value={target}
            onChange={(e) => setTarget(e.target.value)}
          />
          <button className="submit-btn" onClick={handleFormSubmit}>
            Submit
          </button>
        </div>
      )}
      <p>Contact us for more information : <b>7061288682</b></p>
      <p>While creating the fyers app Enter the <b style={{ color: 'yellow', textDecoration: 'none' }}>https://silentrage.online:8000/connect/</b>  in redirect URL</p>
    </div>
  );
};

export default HomePage;
