import React, { useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from '@mui/material';

const api_ip = process.env.REACT_APP_API_IP;
const api_port = process.env.REACT_APP_API_PORT;

const EditUser = ({ user, closeEditModal, onUserUpdate }) => {
  const [formData, setFormData] = useState({
    username: user.username,
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    register_date: user.register_date ? new Date(user.register_date) : null,
    expire_date: user.expire_date ? new Date(user.expire_date) : null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date, fieldName) => {
    if (date) {
      const currentTime = new Date();
      date.setHours(currentTime.getHours());
      date.setMinutes(currentTime.getMinutes());
      date.setSeconds(currentTime.getSeconds());
      setFormData({ ...formData, [fieldName]: date });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const token = localStorage.getItem('token');
    if (!token) {
      console.log({ message: 'No token found. Please login.', type: 'error' });
      return;
    }

    try {
      const response = await axios.put(
        `https://${api_ip}:${api_port}/my_admin/edit-user/${user.id}`,
        formData,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      onUserUpdate(response.data);
      console.log({ message: 'User updated successfully!', type: 'success' });
      closeEditModal();
    } catch (err) {
      console.log({ message: 'Failed to update user!', type: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open onClose={closeEditModal} fullWidth maxWidth="sm">
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                type="email"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="First Name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" gutterBottom>
                Register Date
              </Typography>
              <DatePicker
                selected={formData.register_date}
                onChange={(date) => handleDateChange(date, 'register_date')}
                dateFormat="yyyy-MM-dd"
                className="date-picker"
                placeholderText="Select date"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" gutterBottom>
                Expire Date
              </Typography>
              <DatePicker
                selected={formData.expire_date}
                onChange={(date) => handleDateChange(date, 'expire_date')}
                dateFormat="yyyy-MM-dd"
                className="date-picker"
                placeholderText="Select date"
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeEditModal} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save Changes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUser;
