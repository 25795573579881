import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import axios from 'axios';

import EditUser from './EditUser';

const api_ip = process.env.REACT_APP_API_IP;
const api_port = process.env.REACT_APP_API_PORT;

function AdminPanel() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios
        .get(`https://${api_ip}:${api_port}/my_admin/all_users/`, {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response) => {
          setUsers(response.data);
          setLoading(false);
        })
        .catch(() => {
          setError('Error fetching data');
          setLoading(false);
        });
    } else {
      setError('No token found');
      setLoading(false);
    }
  }, []);

  const handleEditClick = (user) => setSelectedUser(user);

  const handleUserUpdate = (updatedUser) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === updatedUser.id ? { ...user, ...updatedUser } : user
      )
    );
  };

  const handleDeleteClick = (user) => {
    setDeletingUser(user);
    setConfirmationDialogOpen(true); // Open confirmation dialog
  };

  const handleDeleteConfirm = () => {
    const token = localStorage.getItem('token');
    if (token && deletingUser) {
      axios
        .delete(`https://${api_ip}:${api_port}/my_admin/delete-user/${deletingUser.id}`, {
          headers: { Authorization: `Token ${token}` },
        })
        .then(() => {
          setUsers(users.filter((user) => user.id !== deletingUser.id));
          setDeletingUser(null);
          setConfirmationDialogOpen(false);
        })
        .catch(() => {
          setError('Error deleting user');
          setConfirmationDialogOpen(false);
        });
    }
  };

  const handleDeleteCancel = () => {
    setDeletingUser(null);
    setConfirmationDialogOpen(false); // Close confirmation dialog
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography textAlign="center" mt={4}>{error}</Typography>;
  }

  return (
    <Box p={3}>
      {/* Title */}
      <Typography variant="h4" fontWeight="bold" mb={2} textAlign="center" color="primary">
        List of Members
      </Typography>

      {/* Table */}
      <TableContainer component={Paper} elevation={4} sx={{ borderRadius: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="user table">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#e2e2e2' }}>
              {['ID', 'Username', 'Firstname', 'Lastname', 'Email', 'Register Date', 'Membership Expiry', 'Action'].map((heading) => (
                <TableCell key={heading} align="center" sx={{ color: '#000000', fontWeight: 'bold', padding: '12px 16px' }}>
                  {heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{
                  backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff',
                  '&:hover': { backgroundColor: '#f1f1f1' },
                }}
              >
                <TableCell align="center" sx={{ padding: '12px 16px' }}>{row.id}</TableCell>
                <TableCell align="center" sx={{ padding: '12px 16px' }}>{row.username}</TableCell>
                <TableCell align="center" sx={{ padding: '12px 16px' }}>{row.first_name}</TableCell>
                <TableCell align="center" sx={{ padding: '12px 16px' }}>{row.last_name}</TableCell>
                <TableCell align="center" sx={{ padding: '12px 16px' }}>{row.email}</TableCell>
                <TableCell align="center" sx={{ padding: '12px 16px' }}>{row.register_date}</TableCell>
                <TableCell align="center" sx={{ padding: '12px 16px' }}>{row.expire_date}</TableCell>
                <TableCell align="center" sx={{ padding: '12px 16px' }}>
                  <Button variant="outlined" size="small" onClick={() => handleEditClick(row)}>
                    Edit
                  </Button>
                  <Button variant="outlined" color="error" size="small" onClick={() => handleDeleteClick(row)} sx={{ marginLeft: 1 }}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit User Dialog */}
      <Dialog open={!!selectedUser} onClose={() => setSelectedUser(null)} fullWidth maxWidth="sm">
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          {selectedUser && (
            <EditUser
              user={selectedUser}
              closeEditModal={() => setSelectedUser(null)}
              onUserUpdate={handleUserUpdate}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedUser(null)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog for Delete */}
      <Dialog open={confirmationDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this user?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="secondary">Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AdminPanel;
