import React, { createContext, useReducer, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { initialState, reducer } from './reducer/userreducer';
import Login from './components/login.component';
import SignUp from './components/signup.component';
import AdminPanel from './components/AdminPanel';
import Navbar from './Navbar/Navbar1';
import EditUser from './components/EditUser';
import HomePage from './components/homepage';

// Create UserContext
export const UserContext = createContext();
export const SidebarContext = createContext();

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <Router>
      <SidebarContext.Provider value={{ isSidebarOpen, setIsSidebarOpen }}>
      <UserContext.Provider value={{ state, dispatch }}>
        <Navbar />
        <div className="auth-wrapper">
          <div className="auth-inner">
          <div className={`main-content ${isSidebarOpen ? "shifted" : ""}`}>
          <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route path="/sign-in" element={<Login />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/admin-panel-page" element={<AdminPanel />} />
              <Route path="/edit-user-page" element={<EditUser />} />
            </Routes>
         </div>
        </div>
        </div>
      </UserContext.Provider>
      </SidebarContext.Provider>
    </Router>
  );
}

export default App;
